module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Branten Maatwerk","short_name":"B Maatwerk","start_url":"https://www.brantenmaatwerk.nl","background_color":"#00667D","theme_color":"#00667D","display":"standalone","icon":"icon/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-153600146-1"},
    }]
